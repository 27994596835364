.container {
  padding: 32px 36px;
}

.primary {
  background-color: #00b0f0 !important;
  color: white !important;

  &.Mui-disabled {
    opacity: 0.3;
  }
}

body {
  background-color: #f4f4f4;
}

.loader-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20vh;
}

.appbar {
  background-color: #00b0f0 !important;

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 36px;
    padding-right: 36px;

    a {
      color: white;
      text-decoration: none;
    }

    .left {
      display: flex;
      align-items: center;

      svg {
        margin-right: 14px;
      }
    }
  }
}

.chip {
  border-radius: 8px;
  padding: 4px 9px;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: fit-content;
  white-space: nowrap;
}

.chip-container {
  min-width: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.date-picker-container {
  display: flex;
  align-items: center;
  margin-right: 50px;

  h5 {
    margin-right: 25px;
    font-size: 24px;
    font-weight: 700;
    font-family: Roboto;
    margin-bottom: 0;
    margin-top: 0;
  }

  .date-picker {
    width: 300px;
  }
}

.pallet-list-headline {
  margin-top: 2px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;

  .pallet-headline-divider {
    flex-grow: 1;
    height: 2px;
    background-color: black;
    margin-left: 10px;
  }
}

.pallet-list-customers {
  display: flex;

  .pallet-list-custom-select-container {
    display: flex;
    align-items: center;
    margin: 25px 50px 25px 0;

    h5 {
      margin: 0 25px 0 0;
      font-size: 24px;
      font-weight: 700;
      font-family: Roboto;
    }

    .pallet-list-custom-select {
      min-width: 300px;
    }
  }
}

.pallet-item-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;

  .pallet-list-item {
    width: 220px;
    margin-left: 10px;
    margin-right: 10px;

    .item-media {
      width: 220px;
      height: 195px;
    }

    .card-content {
      text-align: center;
    }
  }
}

.pallet-detail-image {
  margin-bottom: 15px;
}
.pallet-detail-thumbnail {
  width: 160px;
  height: 145px;
  object-fit: cover;
  margin-left: 10px;
  margin-right: 10px;

  &.active {
    width: 148px;
    height: 133px;
    border: 6px solid #00a3ff;
  }
}

.pallet-detail-title-container {
  margin-bottom: 24px;
  display: flex;

  .pallet-icon {
    width: 45px;
    height: 41px;
    margin-right: 16px;
  }

  .pallet-detail-title {
    font-weight: 700 !important;
    font-size: 36px !important;
    line-height: 42px !important;

    &.pallet-number {
      font-weight: 400 !important;
    }
  }
}

.inference-list-item-container {
  position: relative;
  margin-bottom: 12px;

  .error-identifier {
    background-color: #db2d2d !important;
    width: 12px;
    height: 12px;
    position: absolute;
    left: -6px;
    top: -6px;
    border-radius: 100%;
  }
}

.inference-list-item {
  min-height: 46px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 10px;

  .right-part {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h1 {
      margin-right: 8px;
    }
  }

  .description {
    flex-grow: 1;
    margin-left: 20px;
    margin-right: 10px;
  }

  .qty-number {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    font-variant: small-caps;
  }

  .qty-text {
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    opacity: 0.3;
  }

  .badge-matched {
    background-color: #0d9c57;
    color: white;
  }

  .badge-not-matched {
    background-color: #db2d2d;
    color: white;
  }

  .badge-audited {
    background-color: #00b0f0;
    color: white;
  }

  .badge-cannot-confirm {
    background-color: #fbc813;
    color: white;
  }
}

.sku-image-container {
  margin: 35px auto;

  .sku-image-item {
    margin: auto 20px;
    min-height: 100px;

    .sku-image {
      height: 75px;
      border: 1px solid black;
      margin: auto;
      display: block;
    }

    p {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      opacity: 0.3;
    }
  }
}

.inference-detail-content {
  margin-top: 40px;
  margin-bottom: 28px;

  .MuiFilledInput-input {
    padding-top: 12px;
  }
}

.new-sku-form {
  .MuiFilledInput-input {
    padding-top: 12px;
  }

  .new-sku-card {
    padding: 12px 12px;
    font-family: Roboto;
  }
}

.inference-detail-label {
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center;
}

.inference-detail-quantity {
  font-weight: bold !important;
  font-size: 25px !important;
  line-height: 56px !important;
  text-align: center;
}

.inference-detail-item {
  margin-right: 60px;
}

.inference-detail-actual-quantity-input {
  font-weight: bold !important;
  font-size: 48px !important;
  line-height: 56px !important;
  text-align: center;
  width: 90px;
  height: 50px;
}

.inference-item-reason-select {
  width: 100%;
}

.image-viewer-container {
  height: calc(100vh - 370px);
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.image-viewer-wrapper {
  position: relative;
}

.overlay-drawer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .overlay {
    position: absolute;
  }
}

.back-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: black;
  text-decoration: none;
  opacity: 0.3;
}

.error-count {
  font-family: Roboto;
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  margin-bottom: 12px;
}

.login-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background: #e5e5e5;

  .login-panel {
    width: 466px;
    height: 525px;
    max-width: 100%;
    text-align: center;


    .logo {
      width: 55%;
      display: block;
      margin: auto;
      margin-top: 44px;
      margin-bottom: 20px;
    }

    h5 {
      font-weight: 900;
      font-size: 36px;
      line-height: 42px;
    }

    .form {
      padding-left: 65px;
      padding-right: 65px;
      margin-top: 52px;

      .error-message {
        color: red;
      }

      .MuiTextField-root {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 100%;
        margin-bottom: 10px;
      }

      .MuiButtonBase-root {
        background: #00b0f0;
        width: 100%;
        height: 45px;
        color: white;
        margin-top: 32px;
      }
    }
  }
}
.weight-heuristic-chip {
  background-color: #fbc813;
  color: black;
}
.complete-audit-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.new-sku-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 12px;
}
.ispickerr-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 12px;
}
.loader-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-indeterminate {
    color: #00b0f0;
  }
}

.confirm-actual-count-button {
  min-width: 200px !important;
}
